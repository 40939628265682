import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Block, Button, StandaloneLink } from "ui-library/atoms";
import { Box, ButtonsLayout, ContentBox, GridItem } from "ui-library/layouts";
import { InfoPanel, LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import userInfo from "core/userInfo";
import { MultilineContent } from "containers/MultilineContent";
import { oneLineNameWithTitles } from "core/formatters";
import { getLocalizedAddressAsList } from "core/localized";

const getActionLinks = (phone, email) => (
    <ButtonsLayout>
        {phone && (
            <Box>
                <Button externalLink={`tel:${phone}`} startIcon={"phone"} color="blue" variant="outlined">
                    {phone}
                </Button>
            </Box>
        )}
        {email && (
            <Box>
                <Button externalLink={`mailto:${email}`} startIcon={"mail"} variant={"outlined"} color={"blue"}>
                    {email}
                </Button>
            </Box>
        )}
    </ButtonsLayout>
);

export const AgentOverviewGridItem = ({ idObject, detailLink }) => {
    const { t } = i18n.useTranslation();

    const agent = useSelector(userInfo.createGetAgentByObjectId(idObject));

    const phone = agent.getIn(["legalEntity", "phone"]);
    const email = agent.getIn(["legalEntity", "email"]);
    const agentAddress = getLocalizedAddressAsList(t, agent.branch);

    return (
        <GridItem xs={12}>
            <Block padding={false} variant="white">
                <InfoPanel
                    icon={"headset"}
                    title={t("agent.agent")}
                    bgVariant={"clean"}
                    actionButton={(phone || email) && getActionLinks(phone, email)}
                    headerAction={
                        <StandaloneLink to={detailLink} color="blue">
                            {t("common.detail")}
                        </StandaloneLink>
                    }
                >
                    <ContentBox>
                        <LabelTextItem label={t("holder.firstName")} text={oneLineNameWithTitles(agent.get("legalEntity"))} />
                        {agent.getIn(["branch", "city"]) && (
                            <LabelTextItem
                                label={t("agent.branch")}
                                text={
                                    <Box>
                                        <Box mb={{ xs: 2, md: 1 }}>
                                            <MultilineContent value={agentAddress} />
                                        </Box>
                                    </Box>
                                }
                            />
                        )}
                    </ContentBox>
                </InfoPanel>
            </Block>
        </GridItem>
    );
};

AgentOverviewGridItem.propTypes = {
    idObject: PropTypes.number.isRequired,
    detailLink: PropTypes.string.isRequired,
};

import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { fromJS } from "immutable";

import avatarDefaultImg from "ui-library/assets/images/avatar-a 1.svg";
import { Block, Button, Typography, UnorderedList } from "ui-library/atoms";
import { Box, ContentBox, GridItem, GridLayout } from "ui-library/layouts";
import { AgentInfoBlock, InfoPanel, LabelTextItem } from "ui-library/modules";

import i18n from "core/i18n";
import userInfo from "core/userInfo";
import { oneLineNameWithTitles } from "core/formatters";
import { getLocalizedOneLineAddressFull } from "core/localized";
import { fn } from "core/util";

const CLOSED_MARK = "X";

const ActionLink = ({ link, title, icon }) => (
    <Box mb={1}>
        <Button color="blue" externalLink={link} startIcon={icon} variant="outlined">
            {title}
        </Button>
    </Box>
);

const formatOpeningHours = (t, openingHours) => (openingHours === CLOSED_MARK ? t("common.closed") : openingHours);

const getAvailabilityTable = (t, agent) =>
    fromJS([
        {
            title: "common.monday",
            value: formatOpeningHours(t, agent.getIn(["branch", "openingTimes", "monday"])),
        },
        {
            title: "common.tuesday",
            value: formatOpeningHours(t, agent.getIn(["branch", "openingTimes", "tuesday"])),
        },
        {
            title: "common.wednesday",
            value: formatOpeningHours(t, agent.getIn(["branch", "openingTimes", "wednesday"])),
        },
        {
            title: "common.thursday",
            value: formatOpeningHours(t, agent.getIn(["branch", "openingTimes", "thursday"])),
        },
        {
            title: "common.friday",
            value: formatOpeningHours(t, agent.getIn(["branch", "openingTimes", "friday"])),
        },
        {
            title: "common.saturday",
            value: formatOpeningHours(t, agent.getIn(["branch", "openingTimes", "saturday"])),
        },
        {
            title: "common.sunday",
            value: formatOpeningHours(t, agent.getIn(["branch", "openingTimes", "sunday"])),
        },
    ]);

const isAnyOpeningHoursRecordNonEmpty = (openingTimes) =>
    openingTimes &&
    (!fn.isEmpty(openingTimes.get("monday")) ||
        !fn.isEmpty(openingTimes.get("tuesday")) ||
        !fn.isEmpty(openingTimes.get("wednesday")) ||
        !fn.isEmpty(openingTimes.get("thursday")) ||
        !fn.isEmpty(openingTimes.get("friday")) ||
        !fn.isEmpty(openingTimes.get("saturday")) ||
        !fn.isEmpty(openingTimes.get("sunday")));

export const ContractAgentTab = ({ idObject }) => {
    const { t } = i18n.useTranslation();

    const agent = useSelector(userInfo.createGetAgentByObjectId(idObject));

    const phone = agent.getIn(["legalEntity", "phone"]);
    const email = agent.getIn(["legalEntity", "email"]);
    const agentAddress = getLocalizedOneLineAddressFull(t, agent.branch);

    const hasAddress = !fn.isEmpty(agentAddress);
    const hasOpeningHours = isAnyOpeningHoursRecordNonEmpty(agent.getIn(["branch", "openingTimes"]));
    const hasProductList = agent.get("productList") && agent.get("productList").size > 0;

    return (
        <GridLayout defaultSpacing>
            <GridItem xs={12}>
                <AgentInfoBlock
                    title={oneLineNameWithTitles(agent.get("legalEntity"))}
                    avatarImg={avatarDefaultImg}
                    actionButton={
                        <Box>
                            {phone && <ActionLink title={phone} link={`tel:${phone}`} icon="phone" />}
                            {email && <ActionLink title={email} link={`mailto:${email}`} icon="mail" />}
                        </Box>
                    }
                >
                    <GridLayout defaultSpacing>
                        {hasProductList && (
                            <GridItem justifyContent="center" md={6} xs={12}>
                                <ContentBox>
                                    <Typography color="black" variant="h4">
                                        {t("agent.weOfferSolutions")}
                                    </Typography>
                                </ContentBox>
                                <UnorderedList compact={true} itemsVariant="check">
                                    {agent
                                        .get("productList")
                                        .map((product) => <Typography key={product}>{product}</Typography>)
                                        .toArray()}
                                </UnorderedList>
                            </GridItem>
                        )}

                        <GridItem justifyContent="center" md={6} xs={12} pl={{ xs: 0, md: 5 }}>
                            <ContentBox>
                                <Typography color="black" variant="h4">
                                    {t("agent.weHelpClients")}
                                </Typography>
                            </ContentBox>
                            <UnorderedList compact={true} itemsVariant="check">
                                <Typography>{t("agent.weHelpClientsSingles")}</Typography>
                                <Typography>{t("agent.weHelpClientsSmallBusiness")}</Typography>
                                <Typography>{t("agent.weHelpClientsBusiness")}</Typography>
                            </UnorderedList>
                        </GridItem>
                    </GridLayout>
                </AgentInfoBlock>
            </GridItem>

            {(hasAddress || hasOpeningHours) && (
                <GridItem xs={12}>
                    <Block padding={false} variant="white">
                        <InfoPanel icon={"map"} title={t("agent.branch")} bgVariant={"clean"}>
                            <Box pb={1}>
                                <Typography variant="p" component="p">
                                    {agentAddress}
                                </Typography>
                            </Box>

                            {hasOpeningHours && (
                                <Box>
                                    {getAvailabilityTable(t, agent)
                                        .map((row) => (
                                            <LabelTextItem key={row.get("title")} label={t(row.get("title"))} text={row.get("value")} />
                                        ))
                                        .toArray()}
                                </Box>
                            )}
                        </InfoPanel>
                    </Block>
                </GridItem>
            )}
        </GridLayout>
    );
};

ContractAgentTab.propTypes = {
    idObject: PropTypes.number.isRequired,
};

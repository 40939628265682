import React, { FC } from "react";
import { useSelector } from "react-redux";
import { ContentBox, GridItem, GridLayout } from "ui-library/layouts";

import i18n from "core/i18n";
import {
    Field,
    InputText,
    lettersNumbersBasicInterpunctionCharacters,
    max20Characters,
    max50Characters,
    required,
    SingleSelect,
} from "core/form";
import { getLocalizedCountryOptions } from "core/localized";

import {
    FIELD_CITY,
    FIELD_COUNTRY_CODE,
    FIELD_DESCRIPTION_NUMBER,
    FIELD_ORIENTATION_NUMBER,
    FIELD_POSTAL_CODE,
    FIELD_STREET,
} from "../../constants";

type AddressFieldsContainerProps = {
    sectionName: string;
};

export const AddressFieldsContainer: FC<AddressFieldsContainerProps> = ({ sectionName }) => {
    const { t } = i18n.useTranslation();
    const countryOptions = useSelector(getLocalizedCountryOptions);

    return (
        <>
            <ContentBox fullWidth>
                <Field
                    name={`${sectionName}.${FIELD_STREET}`}
                    component={InputText}
                    label={t("holder.street")}
                    normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                    validateStatic={[required]}
                />
            </ContentBox>

            <ContentBox fullWidth>
                <GridLayout spacing={2}>
                    <GridItem xs={12} md={6}>
                        <Field
                            name={`${sectionName}.${FIELD_DESCRIPTION_NUMBER}`}
                            component={InputText}
                            label={t("holder.descriptionNumber")}
                            normalize={[lettersNumbersBasicInterpunctionCharacters, max20Characters]}
                            validateStatic={[required]}
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <Field
                            name={`${sectionName}.${FIELD_ORIENTATION_NUMBER}`}
                            component={InputText}
                            label={t("holder.orientationNumber")}
                            normalize={[lettersNumbersBasicInterpunctionCharacters, max20Characters]}
                        />
                    </GridItem>
                </GridLayout>
            </ContentBox>

            <ContentBox fullWidth>
                <GridLayout spacing={2}>
                    <GridItem xs={12} md={9}>
                        <Field
                            name={`${sectionName}.${FIELD_CITY}`}
                            component={InputText}
                            label={t("holder.city")}
                            normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                            validateStatic={[required]}
                        />
                    </GridItem>
                    <GridItem xs={12} md={3}>
                        <Field
                            name={`${sectionName}.${FIELD_POSTAL_CODE}`}
                            component={InputText}
                            label={t("holder.postalCode")}
                            normalize={[lettersNumbersBasicInterpunctionCharacters, max20Characters]}
                            validateStatic={[required]}
                        />
                    </GridItem>
                </GridLayout>
            </ContentBox>

            <ContentBox fullWidth>
                <Field
                    name={`${sectionName}.${FIELD_COUNTRY_CODE}`}
                    component={SingleSelect}
                    label={t("holder.countryCode")}
                    options={countryOptions}
                    validateStatic={[required]}
                />
            </ContentBox>
        </>
    );
};

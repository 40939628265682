import React from "react";
import PropTypes from "prop-types";

import { ContentBox, GridItem, GridLayout } from "ui-library/layouts";

import i18n from "core/i18n";
import {
    Field,
    InputText,
    lettersNumbersBasicInterpunctionCharacters,
    max20Characters,
    max50Characters,
    max6Characters,
    numberSpaceCharacters,
    zip,
} from "core/form";

import {
    CITY_FORM_FIELD,
    DESCRIPTION_NUMBER_FORM_FIELD,
    ORIENTATION_NUMBER_FORM_FIELD,
    STREET_FORM_FIELD,
    ZIP_FORM_FIELD,
} from "../../constants";

const AddressFieldsContainer = ({ sectionName }) => {
    const { t } = i18n.useTranslation();

    return (
        <>
            <ContentBox>
                <Field
                    name={`${sectionName}.${STREET_FORM_FIELD}`}
                    component={InputText}
                    label={t("holder.street")}
                    normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                />
            </ContentBox>

            <ContentBox>
                <GridLayout spacing={2}>
                    <GridItem xs={12} md={6}>
                        <Field
                            name={`${sectionName}.${DESCRIPTION_NUMBER_FORM_FIELD}`}
                            component={InputText}
                            label={t("holder.descriptionNumber")}
                            normalize={[lettersNumbersBasicInterpunctionCharacters, max20Characters]}
                            disableSuccessTick
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <Field
                            name={`${sectionName}.${ORIENTATION_NUMBER_FORM_FIELD}`}
                            component={InputText}
                            label={t("holder.orientationNumber")}
                            normalize={[lettersNumbersBasicInterpunctionCharacters, max20Characters]}
                            disableSuccessTick
                        />
                    </GridItem>
                </GridLayout>
            </ContentBox>

            <ContentBox>
                <GridLayout spacing={2}>
                    <GridItem xs={12} md={9}>
                        <Field
                            name={`${sectionName}.${CITY_FORM_FIELD}`}
                            component={InputText}
                            label={t("holder.city")}
                            normalize={[lettersNumbersBasicInterpunctionCharacters, max50Characters]}
                        />
                    </GridItem>
                    <GridItem xs={12} md={3}>
                        <Field
                            name={`${sectionName}.${ZIP_FORM_FIELD}`}
                            component={InputText}
                            label={t("holder.postalCode")}
                            normalize={[numberSpaceCharacters, max6Characters]}
                            validateStatic={[zip]}
                        />
                    </GridItem>
                </GridLayout>
            </ContentBox>
        </>
    );
};

AddressFieldsContainer.propTypes = {
    sectionName: PropTypes.string.isRequired,
};

export default AddressFieldsContainer;

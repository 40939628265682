import { useEffect } from "react";
import i18n from "core/i18n";
import { sentry } from "core/util";
import { RECAPTCHA_PUBLIC_KEY } from "app/constants";

const loadGoogleRecaptcha = () =>
    new Promise((resolve, reject) => {
        const recaptcha = document.createElement("script");
        recaptcha.type = "text/javascript";
        recaptcha.async = true;
        recaptcha.onload = () => resolve();
        recaptcha.onerror = () => reject(new Error("Error loading Google Recaptcha init script."));
        recaptcha.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_PUBLIC_KEY}&lang=${i18n.getLanguageCode()}`;
        document.body.appendChild(recaptcha);
    }).catch((e) => sentry.captureException(e));

export const ScriptLoader = () => {
    useEffect(() => {
        loadGoogleRecaptcha();
    }, []);
    return null;
};

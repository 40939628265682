import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import i18n from "core/i18n";
import { Field, getFormFieldValue, required, SegmentedControl, SingleSelect } from "core/form";
import localized from "core/localized";
import { ClientType } from "enums";
import { ContentBox, Section } from "ui-library/layouts";

import { ARE_ADDRESSES_IDENTICAL_FIELD, CONTACT_ADDRESS_SECTION, CONTACT_PERSON_TYPE_FIELD, STATE_FORM_FIELD } from "../constants";

import { createToggleCopyAction } from "../createActions";
import IndividualPersonFields from "./IndividualPersonFields";
import CompanyNameField from "./CompanyNameField";
import AddressFieldsContainer from "./AddressFields";

import { createGetContactPersonType, getPermanentAddressCallback, getShowContactPersonCallback } from "../selectors";
import { SectionDescription, SectionTitle } from "../components";

const getPersonFields = (t, personalityCodeOptions, contactPersonType, disabled) => (
    <>
        <ContentBox>
            {!disabled && (
                <Field
                    name={`${CONTACT_ADDRESS_SECTION}.${CONTACT_PERSON_TYPE_FIELD}`}
                    component={SingleSelect}
                    testId={`${CONTACT_ADDRESS_SECTION}.${CONTACT_PERSON_TYPE_FIELD}`}
                    options={personalityCodeOptions}
                    validateStatic={[required]}
                />
            )}
        </ContentBox>
        {getPersonSection(contactPersonType, disabled)}
    </>
);

const getPersonSection = (contactPersonType, disabled) => {
    if (contactPersonType === ClientType.INDIVIDUAL.id) {
        return <IndividualPersonFields sectionName={CONTACT_ADDRESS_SECTION} disabled={disabled} />;
    } else if (contactPersonType === ClientType.COMPANY.id) {
        return <CompanyNameField sectionName={CONTACT_ADDRESS_SECTION} disabled={disabled} />;
    } else {
        return null;
    }
};

const ContactAddressFormBox = ({ formName, isCompany, isUniqaContract, contractCountry }) => {
    const { t } = i18n.useTranslation();
    const copyingEnabled = useSelector(getFormFieldValue(formName, `${CONTACT_ADDRESS_SECTION}.${ARE_ADDRESSES_IDENTICAL_FIELD}`));
    const dispatch = useDispatch();

    const onToggle = () => {
        dispatch(createToggleCopyAction(formName));
    };

    const showPerson = useSelector(getShowContactPersonCallback(formName));
    const contactPersonType = useSelector(createGetContactPersonType(formName));
    const clientTypeCodeOptions = useSelector(localized.getLocalizedClientTypeCodes);
    const permanentAddress = useSelector(getPermanentAddressCallback(formName));

    const isPermAddressCountryOtherThanDomestic = permanentAddress?.get(STATE_FORM_FIELD) !== contractCountry;

    return (
        <Section>
            <SectionTitle title={t("holder.contactAddress")} />
            <SectionDescription
                content={
                    isCompany ? t("form.editPolicyHolder.companyChangeContactAddress") : t("form.editPolicyHolder.changeContactAddress")
                }
            />

            {showPerson && <SectionDescription content={t("holder.emptyContactAddressWarning")} />}

            {isUniqaContract && isPermAddressCountryOtherThanDomestic ? (
                <SectionDescription content={t("holder.toggleAddressNotPossible")} />
            ) : (
                <ContentBox>
                    <Field
                        name={`${CONTACT_ADDRESS_SECTION}.${ARE_ADDRESSES_IDENTICAL_FIELD}`}
                        label={t("holder.contactAddressSameAsPermanent")}
                        component={SegmentedControl}
                        onChange={onToggle}
                    />
                </ContentBox>
            )}

            {showPerson && getPersonFields(t, clientTypeCodeOptions, contactPersonType, copyingEnabled)}

            <AddressFieldsContainer
                formName={formName}
                sectionName={CONTACT_ADDRESS_SECTION}
                disabled={copyingEnabled}
                isUniqaContract={isUniqaContract}
            />
        </Section>
    );
};

ContactAddressFormBox.propTypes = {
    formName: PropTypes.string.isRequired,
    isCompany: PropTypes.bool.isRequired,
    addressesIdentical: PropTypes.bool,
    isUniqaContract: PropTypes.bool,
    contractCountry: PropTypes.string,
};

ContactAddressFormBox.defaultProps = {
    contactPersonType: null,
    addressesIdentical: false,
    isUniqaContract: false,
    contractCountry: null,
};

export default ContactAddressFormBox;
